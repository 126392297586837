<template>
    <div class="monitor-position">
        <div class="bottom">
            <div :class="{'left': true, 'shrink-width': !showNormal, 'expand-width': showNormal}" ref="leftContent" id="leftContent">
                <div class="shrink-expand" @click="shrinkExpand">
                    <span v-show="showNormal" class="btn-shrink"></span>
                    <span v-show="!showNormal" class="btn-expand"></span>
                </div>
                <div :class="{'content-container': true, 'shrink-opa': !showNormal, 'expand-opa': showNormal}">
                    <div class="content-top">
                        <el-input :class="{'search-fence': true,'input-w430-h32': true,'shrink-opa': !showNormal, 'expand-opa': showNormal}" v-model="search" placeholder="请输入围栏名称" clearable @clear="clearable">
                           <!--  <i slot="suffix" v-show="search" class="el-input__icon icon-close" @click="clearSearch"></i> -->
                            <i slot="suffix" class="el-input__icon icon-search" @click="searchFence"></i>
                        </el-input>
                        <div class="drop-and-select">
                            <el-dropdown class="fence-drop" trigger="click" @command="createFence">
                                <div :class="{'border-w0': true,'btn-add-fence': true,'shrink-opa': !showNormal, 'expand-opa': showNormal}"><div class="btn-mask"></div><span class="icon-btn-add"></span><span>新建围栏</span></div>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in options":command="item.name" :key="item.name">{{item.value}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-select v-model="fenceSign" class="fence-sign" @change="changeFenceType">
                                <el-option
                                    v-for="item in fenceSignOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div> 
                    <div class="table-container fence-table" id="tableContainer">
                        <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
            :orderBy.sync="listQuery.orderBy" @pagination="getTableList" :showPage ="false" layout="prev, pager, next"></Table>
                    </div>
                </div>
            </div>
            <div class="map-div" id="mapDiv">
                <div class="map" id="map"></div>
                <map-type ref="mapType" @selectChangeMap="selectChangeMap" @selectChange="selectChange" class="fence-map-type"></map-type>
                <map-bottom @zoomOut="zoomOut" @zoomIn="zoomIn" @changeMapType="changeMapType"></map-bottom> 
            </div>
        </div>
        <!-- 详情 -->
        <el-dialog
            :title="fenceInfoTitle"
            :visible.sync="showFenceInfo"
            width="424px"
            class="pop-up">
            <fence-info :showFenceInfo="showFenceInfo" :treeData="treeData" :fenceInfoObj="fenceInfoObj" @closeInfo="closeInfo" :fenceInfoType="fenceInfoType" @confirmInfo="confirmSetComm"></fence-info>
        </el-dialog>
        <!-- 新增围栏 -->
        <el-dialog
            :title="addFenceTitle"
            :visible.sync="showAddFence"
            width="816px"
            class="pop-up" @close="addFenceCancel">
            <add-fence ref="addFence" :showAddFence="showAddFence" :treeData="treeData" :isAdd="isAdd" :editFenceObj="editFenceObj" :addFenceType="addFenceType" @addFenceCancel="addFenceCancel" @confirmAddFence="confirmAddFence"></add-fence>
        </el-dialog>
    </div>
</template>
<script>
import BaiduMap from '@/components/map/baiduMap';//百度地图
import GoogleMap from '@/components/map/googleMap';//谷歌地图
import mapType from '@/components/map/changeMapType';//切换地图
import mapBottom from '@/components/map/mapBottom'; //地图底部（地图类型、放大缩小）
import Table from '@/components/table/table'; //表格
import btnMore from './btnMore'; //表格中的更多按钮
import fenceInfo from './fenceInfo';//详情
import addFence from './addFence';//新增围栏弹窗
import { getFenceList, addPolygonFence, addCircleFence, deleteFence } from '@/api/map/fence';
import {listTreeOrg} from '@/api/organization-management/index';
import GPS from '@/utils/coordinate';
import {getOrgCode, getOrgId} from '@/utils/cookie'
export default {
    components: {
        mapType,
        mapBottom,
        Table,
        fenceInfo,
        addFence
    },
    data() {
        return {
            showFenceInfo: false,//是否显示详情弹窗
            fenceInfoType: true,//判断是详情弹窗还是编辑详情弹窗
            fenceInfoTitle: '详情',//详情弹窗标题
            showAddFence: false,//是否显示新增围栏弹窗
            addFenceType: true,//判断是新增弹窗还是编辑弹窗
            addFenceTitle: '新增围栏',//新增围栏弹窗标题
            isAdd: false,//新增围栏弹窗中是否禁止编辑
            fenceType: 'polygon',//围栏类型
            fenceSign: 'normalFence',//表格中围栏类型
            fenceSignOptions: [
                {value: 'normalFence', label: '普通围栏'},
                {value: 'publicFence', label: '公共围栏'}
            ],
            showNormal: true,//默认展示
            showFenceAction: false,//是否显示保存围栏按钮
            search: null, //搜索框的值
            map: null, //地图
            center: {//中心点
                lng: 116.3975, 
                lat: 39.9085
            },
            mapOpt: {//地图的基本配置
                zoomControl: true, 
            }, 
            pointList: [],//围栏点
            circleCenter: {},//圆形围栏中心点
            circleRadius: '',//圆形围栏半径
            fence: null,//当前围栏
            isShow: true,//是否显示地图气泡
            bubbleData: [ //气泡内容
                {name: 'IMEI', content: '2018990081111'},
                {name: '状态', content: '在线'},
                {name: '电量', content: '67%'},
                {name: '型号', content: 'KA-101'},
                {name: '关系用户', content: '张三'},
            ],
            btnData: ['告警', '指令', '轨迹'],//气泡下方按钮
            options: [
                {name: 'polygon', value: '多边形围栏'},
                {name: 'circle', value: '圆形围栏'},
            ],
            listQuery:{ //表格搜索条件
                total:10000,//数据条数
                pageIndex: 1,//当前查询页面
                pageSize: 20,//每页数据数量
                orderBy:''
            },
            table: { //表格配置
                configItem: false, //是否开启表格项配置
                data: [],
                header: [
                   /*  {
                        type: 'selection',
                        width: 55,
                        ifShow: true,
                    },  */
                    {
                        prop: 'fenceName',
                        minWidth: '120',
                        label: '围栏名称',
                        tooltip: true,
                        ifShow: true,
                    }, {
                        prop: 'fenceSign',
                        label: '围栏类型',
                        tooltip: true,
                        minWidth: '100',
                        render: (h, param) => {
                            if (param.fenceSign == 'normalFence') {
                                return h('span', '普通围栏');
                            } else if (param.fenceSign == 'publicFence') {
                                return h('span', '公共围栏');
                            }
                        }
                    },
                    {
                        prop: 'options',
                        label: '操作',
                        minWidth: '120',
                        ifShow: true,
                        render: (h, param) => {
                            return h('div',[
                                h('span', {
                                    class: {
                                        'btn-action': true,
                                        'btn-info': true
                                    },
                                    props: {
                                        size: 'small', 
                                    },
                                    on: {
                                        click: () => {
                                            this.listTreeOrg(param.orgId).then( res => {
                                                this.fenceInfoObj = param;
                                                // this.fenceInfoObj.orgName = p;
                                                this.showFenceInfo = true;
                                                this.fenceInfoObj.editType = true;
                                                this.fenceInfoType = true;
                                                this.fenceInfoTitle = '详情';
                                            });
                                        }
                                    }
                                }, '详情'),
                                h(btnMore, {
                                    class: {
                                        'btn-hide': true,
                                        'btn-show': param.fenceSign == 'normalFence' ? true : false
                                    },
                                    on: {
                                        seeMore: (val) => {
                                            if (val == 'delete') {
                                                this.deleteFence(param.id); 
                                            } else if (val == 'common') {
                                                let obj = JSON.parse(JSON.stringify(param))
                                                if (param.fenceType == 'POLYGON_FENCE') {
                                                    this.fence = this.map.showPolygon(param.points);
                                                } else if(param.fenceType == 'CIRCLE_FENCE') {
                                                    this.fence = this.map.showCircle(param.points[0], param.radius);
                                                }
                                                this.setCommFence(obj, 'publicFence');
                                            } else if (val == 'editInfo') {
                                                this.listTreeOrg().then( res => {
                                                    this.showFenceInfo = true;
                                                    this.fenceInfoType = false;
                                                    this.fenceInfoObj = param;
                                                    this.fenceInfoObj.editType = false;
                                                    this.map.clearOverlays();
                                                    this.fenceInfoTitle = '编辑信息';
                                                });
                                                
                                            } else if (val == 'editFence') {
                                                this.listTreeOrg().then( res => {
                                                    this.addFenceTitle = '编辑围栏';
                                                    this.editFenceObj = param;
                                                    this.map.clearOverlays();
                                                    if (param.fenceType == 'POLYGON_FENCE') {
                                                        this.fence = this.map.showPolygon(param.points, {'editable': true});
                                                        this.map.updateCallback(this.fence, this.updateFence, 'polygon');
                                                    } else if(param.fenceType == 'CIRCLE_FENCE') {
                                                        this.fence = this.map.showCircle(param.points[0], param.radius, {'editable': true});
                                                        this.map.updateCallback(this.fence, this.updateFence, 'circle');
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }),
                                h('span', {
                                    class: {
                                        'btn-action': true,
                                        'btn-info': true,
                                        'btn-normal': true,
                                        'btn-hide': true,
                                        'btn-show': param.fenceSign == 'publicFence' ? true : false
                                    },
                                    on: {
                                        click: () => {
                                            // this.showFenceInfo = true;
                                            let obj = JSON.parse(JSON.stringify(param))
                                            if (param.fenceType == 'POLYGON_FENCE') {
                                                this.fence = this.map.showPolygon(param.points);
                                            } else if(param.fenceType == 'CIRCLE_FENCE') {
                                                this.fence = this.map.showCircle(param.points[0], param.radius);
                                            }
                                            this.setCommFence(obj, 'normalFence');
                                        }
                                    }
                                }, '设为普通'),
                            ]);
                        }
                    }
                ]
            },
            fenceInfoObj: {
                fenceName: '',//围栏名称
                orgCode: '',//组织机构
                orgName: '',//组织机构名称
                bindImeiSet: [],
            },//设备详情
            editFenceObj: {},
            ruleFormCopy: {
                fenceName: '',//围栏名称
                id: null,//围栏id
                orgCode: getOrgId(), //组织机构
                orgName: '',//组织机构名称
                inFenceTime: '',//进围栏时间
                inFenceType: '',//进围栏时间类型
                outFenceTime: '',//出围栏时间
                outFenceType: '', //出围栏类型
                bindImeiSet: [],//复选框选中的值
                orgName: '',//
                fenceAction: [],
                triggeringCondition: '',//选择进围栏还是出围栏
                inFence: { //进围栏
                    stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'IN'
                },
                outFence: {//出围栏
                    stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'OUT'
                },
                conditionQueryList: [],//长时间围栏   
            },
            treeData: [],//组织机构数据
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {
        this.calcHeight();
        this.map = new BaiduMap();
        setTimeout(() => {
            this.map.initMap('map', this.center, 15);
        }, 500);
        this.handleScroll();
    },
    methods: {
        /**
         * 获取机构数据
         * @param {Number} orgId 机构id
         */
        listTreeOrg(orgId){
            return new Promise( ( resolve , reject ) => {
                listTreeOrg({currentOrgId:orgId,excludeChild:true}).then( res => {
                    if( res.code == 200 ) {
                        this.treeData = res.data;
                        resolve(res)
                    }
                })
            }) 
        },
        /** 切换围栏
         * 
         */
        changeFenceType(val) {
            this.getTableList();
        },
        /** 计算表格高度
         * 
         */
        calcHeight() {
            let height = this.$refs.leftContent.clientHeight - 260;
            var dom = document.querySelector('.el-table__body-wrapper');
            dom.style.cssText = 'height: '+height+'px !important';
        },
        /** 左边放大缩小
         * 
         */
        shrinkExpand() {
            this.showNormal = !this.showNormal
        },
        /** 
         * 搜索围栏
         */
        searchFence() {
            this.table.data = [];
            this.getTableList();
        },
        /** 
         * 创建围栏 
         * @param {String} val 选中的值
        */
        createFence(val) {
            this.map.clearOverlays();
            this.listTreeOrg().then( res => {
                this.showFenceAction = true;
                this.showNormal = false;
                this.map.createFence(val, null, null, this.fenceCallBack);
                this.addFenceTitle = '新增围栏';
                this.isAdd = false;
                if (res.data.length > 0) {
                    this.ruleFormCopy.orgName = res.data[0].orgName;
                }
                this.editFenceObj = JSON.parse(JSON.stringify(this.ruleFormCopy)); 
            });
        },
        /** 
         *创建围栏后的回调 
         */
        fenceCallBack(e) {
            this.fence = e.overlay;
            if (this.$refs.mapType.selectMap == 'google') {
                this.fenceType = e.type;
                if (e.type == 'polygon') {
                    this.pointList = [];
                    let tempPointList = e.overlay.getPath().getArray();
                    tempPointList.forEach(item => {
                        this.pointList.push(item.toJSON())
                    });
                    if (this.pointList.length < 3) {
                        this.$message({
                            message: '请至少选择3个点',
                            type: 'warning'
                        });
                        this.map.clearFence(e.overlay);
                        return false;
                    }
                } else if (e.type == 'circle') {
                    this.circleCenter = e.overlay.center.toJSON();
                    this.circleRadius = e.overlay.radius;
                }
            } else if (this.$refs.mapType.selectMap == 'baidu') {
                this.fenceType = e.drawingMode;
                if (e.drawingMode == 'polygon') {
                    this.pointList = e.overlay.getPath();
                    if (this.pointList.length < 3) {
                        this.$message({
                            message: '请至少选择3个点',
                            type: 'warning'
                        });
                        this.map.clearFence(e.overlay);
                        return false;
                    }
                } else if (this.fenceType == 'circle') {
                    this.circleCenter = e.overlay.getCenter();
                    this.circleRadius = e.overlay.getRadius();
                }
            }
            this.showAddFence = true;
        },
        /** 地图切换
         * @param {String} val 选择的地图
         */
        selectChangeMap(val) {
            this.map.clearMap('map');
            if (val == 'google') {
                this.map = new GoogleMap();
            } else if (val == 'baidu') {
                this.map = new BaiduMap();
            }
            this.map.initMap('map', this.center, 15);
        },
        /** 切换地图类型
         *@param {String} val 地图类型 
         */
        changeMapType(val) {
            this.map.setMapType(val);
        },
        /** 切换工具箱
         * @param {String} val 工具箱选中的值
         */
        selectChange(val) {
            if (val == 'fullScreen') {
                this.map.fullScreen('mapDiv');
            } else if (val == 'ranging') {
                this.rangeDistance();
            }
        },
        /** 
         * 调用测距
         */
        rangeDistance() {
            this.map.computeLength();
        },
        /** 放大地图
         * 
         */
        zoomOut() {
            this.map.zoomOut();
        },
        /** 缩小地图
         * 
         */
        zoomIn() {
            this.map.zoomIn();
        },
        /** 获取围栏数据
         * @param {Boolean} flag 判断是否是滚动翻页
         */
        getTableList(flag) {
            let obj = {
                fenceName: this.search,
                fenceSign: this.fenceSign,
                fullUserId: getOrgCode(),
                pageIndex: this.listQuery.pageIndex || 1,
                pageSize: this.listQuery.pageSize || 20
            }
            // this.table.data = [];
            getFenceList(obj).then(res => {
                if (res.code == 200) {
                    if (flag) {
                            this.table.data.concat(res.data)
                    } else {
                        this.table.data = res.data;
                    }
                    this.listQuery.total = res.count;
                }
            })
        },
        /** 关闭新增围栏弹窗
         * 
         */
        addFenceCancel() {
            this.showAddFence = false;
            this.map.clearFence(this.fence);
            this.showNormal = true;
            this.map.clearOverlays();
        },
        /** 确定新增围栏 
         * @param {Object} obj 围栏传参
        */
        confirmAddFence(obj) {
            if (this.fenceType == 'polygon') {
                let pointArr = [];
                if (this.$refs.mapType.selectMap == 'google') {
                    this.pointList.forEach(item => {
                        let lnglat = GPS.chinaToGPS(item.lat, item.lng);
                        pointArr.push(lnglat);
                    });
                } else if (this.$refs.mapType.selectMap == 'baidu') {
                    this.pointList.forEach(item => {
                        let lnglat = GPS.baiduToGPS(item.lat, item.lng);
                        pointArr.push(lnglat);
                    });
                }
                obj.pointList = pointArr;
                this.addPolygonFence(obj);
            } else if (this.fenceType == 'circle') {
                if (this.$refs.mapType.selectMap == 'google') {
                     obj.center = GPS.chinaToGPS(this.circleCenter.lat, this.circleCenter.lng);
                } else if (this.$refs.mapType.selectMap == 'baidu') {
                    obj.center = GPS.baiduToGPS(this.circleCenter.lat, this.circleCenter.lng);
                }
                // obj.center = this.circleCenter;
                obj.radius = this.circleRadius;
                this.addCircleFence(obj);
            }
        },
        /** 新增多边形围栏
         * @param {Object} obj 参数
         */
        addPolygonFence(obj) {
            obj.fullUserId = getOrgCode();
            addPolygonFence(obj).then(res => {
                if (res) {
                    if (res.code == '200') {
                        this.showAddFence = false;
                        this.showFenceInfo = false;
                        this.map.clearFence(this.fence);
                        this.map.clearOverlays();
                        this.showNormal = true;
                        this.$message.close();
                        this.getTableList();
                    }
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                }
                
            }); 
        },
        /** 新增圆形围栏
         * @param {object} obj 参数
         */
        addCircleFence(obj) {
            if (obj.radius == 0) {
                obj.radius = 200;
            }
            obj.fullUserId = getOrgCode();
            addCircleFence(obj).then(res => {
                if (res) {
                    if (res.code == '200') {
                        this.showAddFence = false;
                        this.showFenceInfo = false;
                        this.map.clearFence(this.fence);
                        this.map.clearOverlays();
                        this.showNormal = true;
                        this.$message.close();
                        this.getTableList();
                    }
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                }
            });
        },
        /** 关闭详情
         * 
         */
        closeInfo() {
            this.showFenceInfo = false;
        },
        /** 删除围栏 
         * @param {String} id 围栏id
        */
        deleteFence(id) {
             this.$confirm('是否删除围栏?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.confirmDelete(id);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        /** 确定删除围栏
         * @param {String} id 围栏id
         */
        confirmDelete(id) {
            let obj = {
                'id': id
            };
            deleteFence(obj).then(res => {
                this.$message({
                    type: 'success',
                    message: res.message
                });
                var tempdata = [];
                var allData = JSON.parse(JSON.stringify(this.table.data));
                allData.forEach(item => {
                    if (item.id != id) {
                        tempdata.push(item);
                    }
                });
                this.table.data = tempdata;
                this.getTableList();
            })
        },
        /** 设为围栏类型(普通围栏/公共围栏)
         * @param {Object} obj 参数
         */
        setCommFence(obj, type) {
            let tempObj = JSON.parse(JSON.stringify(obj));
            tempObj.conditionQueryList = tempObj.conditionVo;
            delete tempObj.conditionVo;
            delete tempObj.id;
            tempObj.fenceSign = type;
            let title = type == 'publicFence' ? '确定设为公共围栏?' : '确定设为普通围栏?';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let type = '';
                if (tempObj.fenceType == 'POLYGON_FENCE') {
                    tempObj.pointList = tempObj.points;
                    type = 'polygon';
                } else if (tempObj.fenceType == 'CIRCLE_FENCE') {
                    type = 'circle';
                    tempObj.center = tempObj.points[0];
                }
                delete tempObj.points;
                this.confirmSetComm(tempObj, type);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            }); 
        },
        /** 确定设置围栏 
         * @param {Object} obj 参数
         * @param {String} type 判断时多边形围栏还是圆形围栏
        */
        confirmSetComm(obj, type) {
            if (type == 'polygon') {
                this.addPolygonFence(obj);
            } else if (type == 'circle') {
                this.addCircleFence(obj);
            }
        },
        /** 监听地图更新围栏事件
        * @param {Object} obj 点
        * @param {String} type 围栏类型
        */
        updateFence(obj, type) {
            this.isAdd = true;
            this.showAddFence = true;
            this.fenceType = type;
            if (type == 'polygon') {
                this.pointList = obj;
            } else if (type == 'circle') {
                this.circleCenter = obj.center;
                this.circleRadius = obj.radius;
            }
        },
        /** 计算表格滚动条高度
         * 
         */
        handleScroll() {
            var that = this;
            var dom = document.querySelector('.el-table__body-wrapper');
            // console.log(dom.clientHeight);
            dom.addEventListener('scroll', function() {
                var len = dom.scrollHeight - dom.clientHeight - dom.scrollTop;
                if (len == 0) {
                    if (that.listQuery.pageIndex < (Math.ceil(that.listQuery.total / that.listQuery.pageSize))) {
                        that.listQuery.pageIndex++;
                        that.getTableList(true);
                    } else {
                        that.listQuery.pageIndex = (Math.ceil(that.listQuery.total / that.listQuery.pagesize));
                    }
                }
            })
        },
        /** 清空搜索框 */
        clearable() {
            this.search = '';
        },
    },
}
</script>
<style scoped lang="scss">
    .monitor-position {
        position: relative;
        height: 100%;
        .bottom {
            display: flex;
            position: absolute;
            top: 0;
            right: 20px;
            bottom: 0;
            left: 0;
            width: 100%;
            .left {
                position: relative;
                width: 494px;
                box-shadow: 12px 0px 48px 16px rgba(0, 0, 0, 0.03), 9px 0px 28px 0px rgba(0, 0, 0, 0.05), 6px 0px 16px -8px rgba(0, 0, 0, 0.08);
                border-radius: 8px 0px 0px 8px;
                z-index: 4;
                transition: all 1s;
                background-color: #fff;
                &.shrink-width {
                    width: 16px;
                }
                &.expand-width {
                    width: 494px;
                }
                .shrink-expand {
                    position: absolute;
                    top: 30px;
                    right: -16px;
                    span {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }
                    .btn-shrink {
                        background: url(../../assets/image/map/btn-unfold.png);
                        &:hover {
                            background: url(../../assets/image/map/btn-unfold-select.png);
                        }
                    }
                    .btn-expand {
                        background: url(../../assets/image/map/btn-shrink.png);
                        &:hover {
                            background: url(../../assets/image/map/btn-shrink-select.png);
                        }
                    }
                }
                .content-container {
                    transition: opacity 1s ease-in-out;
                    margin: 32px;
                    &.expand-opa {
                        opacity: 1;
                    }
                    &.shrink-opa {
                        opacity: 0;
                    }
                    .content-top {
                        .search-fence {
                            width: 430px;
                            height: 32px;
                            transition: all 1s ease-in-out;
                            &.expand-opa {
                                width: 430px !important;
                            }
                            &.shrink-opa {
                                width: 0 !important;
                            }
                        }
                        .btn-add-fence {
                            // float: right;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 108px;
                            height: 32px;
                            padding: 0;
                            line-height: 32px;
                            border-radius: 4px;
                            text-align: center;
                            color: #fff;
                            background-color: #3270FF;
                            transition: all 1s ease-in-out;
                            cursor: pointer;
                            &.expand-opa {
                                width: 108px;
                            }
                            &.shrink-opa {
                                width: 0;
                            }
                            .btn-mask {
                                display: none;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background: rgba(0,0,0,.1);
                            }
                            &:hover .btn-mask,
                            &:active .btn-mask {
                                display: block;
                                outline: none;
                            }
                        }
                        .icon-btn-add {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            margin-right: 2px;
                            background: url(../../assets/image/fence/btn-add.png);
                        }
                        .drop-and-select  {
                            margin-top: 23px;
                            .fence-sign {
                                float: right;
                            }
                        }
                    }
                    .table-container {
                        margin-top: 32px;
                    }
                }
            }
            .map-div {
                flex: 1;
                .map {
                    width: 100%;
                    height: 100%;
                }
                .fence-map-type {
                    top: 46px;
                }
            }
        }
    }
</style>
<style>
    .search-fence .icon-search {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(../../assets/image/fence/icon-search.png);
    }
    .search-fence .icon-close {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(../../assets/image/fence/icon-close.png);
    }
    .search-fence .el-input__suffix {
        display: flex;
        align-items: center;
    }
    .search-fence .el-input__suffix-inner .icon-close {
        margin-right: 6px;
    }
    .search-fence .el-input__suffix-inner {
        display: flex;
        align-items: center;
        pointer-events: auto;
        flex-direction: row-reverse;
        cursor: pointer;
    }
    .btn-add-fence .el-input__inner {
        height: 32px;
    }
    .btn-add-fence .el-input__icon {
        line-height: 32px;
    }
    .table-container .btn-info {
        margin-right: 24px;
    }
    .table-container .btn-normal {
        margin-right: 20px;
    }
    .table-container .btn-action {
        font-size: 14px;
        color: #3270FF;
        cursor: pointer;
    }
    .table-container .btn-hide {
        display: none;
    }
    .table-container .btn-show {
        display: inline-block;
    }
    .shrink-opa .el-input__suffix {
        height: 0;
    }
    .fence-sign .el-input__inner {
        width: 103px;
        border: 0;
        color: #262626;
    } 
    .fence-sign .el-input__suffix-inner .el-input__icon {
        line-height: 32px;
    }
    .monitor-position .table-components {
        height: 100%;
    }
    .el-select-dropdown__item.selected {
        font-weight: 400;
    }
    .fence-table .el-table .cell,
    .fence-table .el-table th>.cell {
        padding-left: 24px;
    }
    .search-fence .el-input__suffix {
        right: 12px;
    }
</style>
