<template>
    <div class="fence-info-container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="68px" class="demo-ruleForm data-from">
            <el-form-item label="围栏名称" prop="fenceName">
                <el-input class="inputw-w290-h32" v-model="ruleForm.fenceName" :disabled="showType"></el-input>
            </el-form-item>
            <el-form-item label="组织机构" prop="orgCode" class="el-w292">
                <popover v-show="!showType"  v-model="openOrClose" @nodeClick="nodeClick" ref="tree" :isDisabled="showType" :data="newTreeData"  @hide="openOrClose = false" @show="openOrClose = true">
                    <div slot="treeReference" >
                        <div class="org-input fence-org-input">
                            <el-input  class="input-w290-h32" disabled :class="openOrClose ? 'pop-open-style' : 'pop-close-style'" v-model="orgName"></el-input>
                            <svg-icon :class="{'svg-icon': true, 'icon-link': true, 'icon-transform': !openOrClose} " icon-class="icon-select" size="16"></svg-icon>
                        </div>
                    </div>
                </popover> 
                 <div v-show="showType">
                    <!-- <el-form-item prop="orgPname"> -->
                    <el-input class="input-w290-h32" disabled v-model="orgName"></el-input>
                    <!-- </el-form-item> -->
                </div>
            </el-form-item>
            <div class="equiment-container">
                <el-form-item label="关联设备" class="equiment-label">
                </el-form-item>
                <el-form-item label=''  prop="equiment" class="equiment-content-container">
                    <div :class="{'equiment-content': true, 'disable-equiment': showType}">
                        <ul>
                            <li v-for="(item, i) in linkEquimentList" :key="i" v-show="showType">
                                <svg-icon class="svg-icon icon-link" icon-class="icon-link" size="16"></svg-icon>
                                {{item.imei}}
                            </li> 
                            <el-checkbox-group v-model="ruleForm.bindImeiSet" v-show="!showType">
                                <li v-for="(item, i) in linkEquimentList" :key="i">
                                    <el-checkbox :label="String(item.imei)" :key="i"></el-checkbox>
                                </li>
                            </el-checkbox-group> 
                        </ul>
                    </div>
                </el-form-item>
            </div>
            <el-form-item class="m-b0">
                <div class="btn-container" v-show="showType">
                    <tem-btn class="btn-close"  value="关闭" @click="closeInfo('ruleForm')"></tem-btn>
                </div> 
            </el-form-item>
            <el-form-item>
                <div class="btn-container" v-show="!showType">
                    <tem-btn type="info" class="btn-cancel"  value="取消" @click="closeInfo('ruleForm')"></tem-btn>
                    <tem-btn class="btn-close"  value="确定" @click="confirmInfo('ruleForm')"></tem-btn>
                </div> 
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import popover from '@/components/popover/index';
import {listTreeOrg} from '@/api/organization-management/index';
import {getDeviceList} from '@/api/map/fence';
    export default {
        props: {
            type: {
                type: String,
                default: 'info'
            },
            fenceInfoObj: {
                type: Object,
                default: () => {
                    return {
                        fenceName: '',//围栏名称
                        orgCode: '',//组织机构
                        bindImeiSet: [],
                    };
                }
            },
            fenceInfoType: {
                type: Boolean,
                default: true,
            },
            treeData:{
                type: Array,
                default:() => {
                    return []
                }
            },
            showFenceInfo:{
                type: Boolean,
                default: false
            }
        },
        components: {
            popover
        },
        data() {
            return {
                openOrClose: false,//是否展开组织机构
                newTreeData: this.treeData,//组织机构数据
                isShow: true,//判断是显示设备详情还是关联设备
                showType: this.fenceInfoType,//判断是否显示编辑信息
                ruleForm: this.fenceInfoObj,
                rules: {
                    fenceName: [
                        {required: true, message: '请输入围栏名称', trigger: 'change'},
                        { max: 20, message: '最长输入20个字', trigger: 'change' }
                    ]
                },
                orgnationList: [ //组织机构
                    {name: '', value: ''}
                ],
                linkEquimentList: [
                    {id: 1, imei: 11234567878},
                    {id: 2, imei: 11234567879},
                    {id: 3, imei: 11234567870},
                    {id: 4, imei: 11234567871},
                ], //关联的设备
                allEquiment: [],//所有设备
                checkList: [],//复选框选中的值
                orgName: '',//组织机构名称
            };
        },
        created() {
            this.isShow = this.type == 'info'? true : false;
            // this.getDeviceList();
        },
        methods: {
            /** 选择机构
             * @param {String} val 选择的机构
             */
            selectChange(val) {

            },
            /** 关闭详情
             * 
             */
            closeInfo(val) {
                this.$refs[val].resetFields();
                this.$emit('closeInfo');
                this.openOrClose = false
            },
            /** 点击节点
             * @param {Object} obj 选中的机构
            */
            nodeClick(obj, flag) {
                this.ruleForm.orgCode = obj.orgId;
                this.ruleForm.fullUserId = obj.orgId;
                this.getDeviceList();
                this.orgName = obj.orgName;
                this.fenceInfoObj.orgName = obj.orgName;
                if(flag == 'db') {
                    this.openOrClose = false
                }
            },
            /** 获取设备
             */
            getDeviceList() {
                let obj = {
                    orgId: this.ruleForm.orgCode
                };
                getDeviceList(obj).then(res => {
                    if (res.code == 200) {
                        this.linkEquimentList = res.data;
                    }
                });
            },
            /** 确认详情
             * 
             */
            confirmInfo(val) {
                this.$refs[val].validate((valid) => {
                    if (!valid) {
                        return
                    }
                    let tempObj = JSON.parse(JSON.stringify(this.ruleForm));
                    tempObj.conditionQueryList = tempObj.conditionVo;
                    delete tempObj.conditionVo;
                    let type = '';
                    if (tempObj.fenceType == 'POLYGON_FENCE') {
                        tempObj.pointList = tempObj.points;
                        type = 'polygon';
                    } else if (tempObj.fenceType == 'CIRCLE_FENCE') {
                        type = 'circle';
                        tempObj.center = tempObj.points[0];
                    }
                    /* tempObj.id = tempObj.id;
                    delete tempObj.id; */
                    delete tempObj.points;
                    this.$emit('confirmInfo', tempObj, type);
                    this.openOrClose = false
                })
            }
        },
        watch: {
            fenceInfoObj: {
                handler(val) {
                    if (val.editType) {
                        var arr = [];
                        val['bindImeiSet'].forEach(item => {
                            var obj = {
                                imei: item
                            }
                            arr.push(obj);
                        })
                        this.showType = true;
                        this.allEquiment = arr;
                        this.linkEquimentList = arr;
                    }
                    this.ruleForm.bindImeiSet = val['bindImeiSet'];
                    this.orgName = val.orgName;
                    this.ruleForm = val;
                },
                deep: true,
                immediate: true
            },
            fenceInfoType: {
                handler(val) {
                    if (!val) {
                        this.getDeviceList();
                    } else {
                        this.linkEquimentList = this.allEquiment;
                    }
                    this.showType = val;
                },
                deep: true,
                immediate: true
            },
            treeData:{
                handler(newVal, oldVal) {
                    this.newTreeData = newVal;
                },
                deep: true,
            },
            showFenceInfo:{
                handler(val) {
                    if (!val) {
                        this.$refs.ruleForm.resetFields()
                    }
                },
                deep: true
            }
        }
    }
</script>
<style scoped lang="scss">
    .fence-info-container {
        .select-origntion {
            width: 292px;
        }
        .equiment-container {
            .equiment-label {
                margin-bottom: 0;
            }
            .equiment-content-container { 
                width: 360px;
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                .equiment-content {
                    padding: 16px 32px;
                    max-height: 336px;
                    overflow: auto;
                    ul {
                        li {
                            display: flex;
                            align-items: center;
                            height: 32px;
                            line-height: 32px;  
                            font-weight: 400; 
                            color: #595959;
                            .icon-link {
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                margin-right: 8px;
                                background: url(../../assets/image/fence/icon-link.png);
                            }
                        }
                    }
                    
                }
                
            }
        }
        .btn-container {
            float: right;
            padding-top: 10px;
            padding-bottom: 32px;
            .btn-cancel {
                float: left;
                margin-right: 12px;
            }
        }
    }
</style>
<style >
    .equiment-content-container .el-form-item__content {
        margin-left: 0 !important;
    }
    .fence-info-container .el-form-item__content,
    .fence-info-container .el-input__inner {
        line-height: 32px !important;
    }
    .equiment-content-container .el-checkbox {
        color: #595959;
    }
    .m-b0 {
        margin-bottom: 0;
    }
    .popover-fence.organization-popover .el-tree {
        height: 190px;
    }
    .popover-fence .org-input .icon-link {
        top: 12px !important; 
    }
    .disable-equiment {
        background: #F7FAFC;
        cursor: not-allowed;
    }
</style>