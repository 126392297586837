<template>
     <div class="btn-more-container">
         <el-dropdown class="fence-drop" trigger="click" @command="seeMore">
            <span class="btn-more">更多</span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in options":command="item.name" :key="item.name">{{item.value}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
     </div>
</template>
<script>
    export default {
        data() {
            return {
                options: [
                    {name: 'editFence', value: '编辑围栏'},
                    {name: 'editInfo', value: '编辑信息'},
                    {name: 'common', value: '设为公共'},
                    {name: 'delete', value: '删除'},
                ]
            };
        },
        methods: {
            /** 查看更多 
             * @param {String} val 选中的值
            */
            seeMore(val) {
                this.$emit('seeMore', val);
            }
        }
    }
</script>
<style scoped lang="scss">
    .btn-more-container {
        display: inline-block;
        line-height: 10px;
        .btn-more {
            color: #3270FF;
            cursor: pointer;
        }
    }
</style>