import request from '@/utils/request';
/** 
 * 搜索围栏
 */
export function getFenceList(data) {
    return request({
        // url: '/fence/select',//请求路径
        url: '/fence/selectExt',
        method: 'get',
        params: data
    });
}
/** 
 * 新增多边形围栏
 */
export function addPolygonFence(data) {
    return request({
        url: '/fence/polygonFence',
        method: 'post',
        data
    });
}
/** 
 * 新增圆形围栏
 */
export function addCircleFence(data) {
    return request({
        url: '/fence/circleFence',
        method: 'post',
        data
    });
}
/* 删除围栏 */
export function deleteFence(data) {
    return request({
        url: '/fence/delete',
        method: 'get',
        params: data
    });
}
/* 获取设备信息 */
export function getDeviceList(data) {
    return request({
        url: '/base/device/listDeviceByOrgId',
        method: 'get',
        params: data
    });
}