<template>
     <div class="add-fence-container clearfix data-form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="68px" class="add-fenceForm">
            <el-form-item label="围栏名称" prop="fenceName" class="left el-w292">
                <el-input class="inputw-w290-h32" :maxlength="20" v-model="ruleForm.fenceName" :disabled="allowEdit"></el-input>
            </el-form-item>
            <el-form-item label="组织机构" prop="orgCode" class="m-l32 left el-w292">
                <popover v-show="!allowEdit" v-model="openOrClose" addNewClass="popover-fence" @nodeClick="nodeClick" ref="tree" :isDisabled="allowEdit" :data="newTreeData"  @hide="openOrClose = false" @show="openOrClose = true">
                    <div slot="treeReference" >
                        <div class="org-input">
                            <el-form-item prop="orgPname">
                                <el-input class="input-w290-h32" disabled :class="openOrClose ? 'pop-open-style' : 'pop-close-style'" v-model="ruleForm.orgName"></el-input>
                            </el-form-item>
                            <svg-icon :class="{'svg-icon': true, 'icon-link': true, 'icon-transform': !openOrClose} " icon-class="icon-select" size="16"></svg-icon>
                        </div>
                    </div>
                </popover>
                <div v-show="allowEdit">
                    <el-form-item prop="orgPname">
                        <el-input class="input-w290-h32" disabled v-model="ruleForm.orgName"></el-input>
                    </el-form-item>
                </div>
            </el-form-item>
           <div class="equiment-container"> 
                <div class="equiment-label">关联设备</div>
                <el-form-item label=''  prop="equiment" class="equiment-content-container">
                    <div class="equiment-content">
                        <ul>
                            <el-checkbox-group v-model="ruleForm.bindImeiSet">
                                <li v-for="(item, i) in linkEquimentList" :key="i">
                                    <el-checkbox :label="String(item.imei)" :disabled="allowEdit"></el-checkbox>
                                </li>
                            </el-checkbox-group>
                        </ul>
                    </div>
                </el-form-item>
            </div>
            <el-form-item class="m-l0 m-b0">
                <div class="alarm-trigger-container">
                    <div class="clearfix">
                        <div class="alarm-header-title">告警触发条件</div>
                        <div class="check-fence-action">
                            <el-checkbox-group v-model="ruleForm.fenceAction" @change="changeCheckFence">
                                <el-checkbox label="IN" :disabled="allowEdit">进入围栏</el-checkbox>
                                <el-checkbox label="OUT" :disabled="allowEdit">离开围栏</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="alarm-content-container clearfix">
                        <el-row>
                            <el-col :span="24" v-show="isFenceIn">
                                <div class="alarm-item clearfix m-b12">
                                    <div class="alarm-title">进入围栏时间大于</div>
                                    <div class="alarm-content">
                                        <el-select :disabled="allowEdit" class="select-first" v-model="ruleForm.inFence['stayTime']" placeholder="请选择">
                                            <el-option
                                                v-for="(item, i) in inFenceTimeList"
                                                :key="i"
                                                :label="item"
                                                :value="item">
                                            </el-option>
                                        </el-select> 
                                        <el-select :disabled="allowEdit" class="select-first select-second" v-model="ruleForm.inFence['timeUnit']" placeholder="请选择">
                                            <el-option
                                                v-for="(item, i) in typeList"
                                                :label="item.value"
                                                :value="item.name" :key="i" @click.native="changeInFenceType">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div> 
                            </el-col>
                            <el-col :span="24" v-show="isFenceOut">
                                <div class="alarm-item clearfix m-b12">
                                    <div class="alarm-title">离开围栏时间大于</div>
                                    <div class="alarm-content">
                                        <el-select :disabled="allowEdit" class="select-first" v-model="ruleForm.outFence['stayTime']" placeholder="请选择">
                                            <el-option
                                                v-for="(item, i) in outFenceTimeList"
                                                :key="i"
                                                :label="item"
                                                :value="item">
                                            </el-option>
                                        </el-select>
                                        <!-- <el-select class="select-first select-second" v-model="ruleForm.outFenceType" placeholder="请选择"> -->
                                        <el-select :disabled="allowEdit" class="select-first select-second" v-model="ruleForm.outFence['timeUnit']" placeholder="请选择">
                                            <el-option
                                                v-for="(item, i) in typeList"
                                                :label="item.value"
                                                :value="item.name" :key="i" @click.native="changeOutFenceType">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </el-col> 
                        </el-row>
                    </div>
                </div>
            </el-form-item>
            <el-form-item class="m-b12">
                <div class="btn-container">
                    <tem-btn type="info" class="btn-cancel"  value="取消" @click="addFenceCancel('ruleForm')"></tem-btn>
                    <tem-btn class="btn-close"  value="确定" @click="confirmAddFence('ruleForm')"></tem-btn>
                </div>
            </el-form-item>
        </el-form>
     </div>
</template>
<script>
// import { delete } from 'vue/types/umd';
import popover  from '@/components/popover/index';
import {listTreeOrg} from '@/api/organization-management/index';
import {getDeviceList} from '@/api/map/fence';
    export default {
        components:{
            popover
        },
        props: {
            showAddFence: {
                type: Boolean,
                default: false,
            },
            editFenceObj: {
                type: Object,
                default: () => {
                    return {
                        fenceName: '',//围栏名称
                        orgCode: '', //组织机构
                        orgName: '',//组织名称
                        inFenceTime: '',//进围栏时间
                        inFenceType: '',//进围栏时间类型
                        outFenceTime: '',//出围栏时间
                        outFenceType: '', //出围栏类型
                        bindImeiSet: [],//复选框选中的值
                        id: null,//围栏id
                        fenceAction: [],
                        triggeringCondition: '',//选择进围栏还是出围栏
                        inFence: { //进围栏
                            stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'IN'
                        },
                        outFence: {//出围栏
                            stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'OUT'
                        },
                        conditionQueryList: [],//长时间围栏
                    }
                }
            },
            isAdd: {
                type: Boolean,
                default: false
            },
            treeData:{
                type: Array,
                default:() => {
                    return []
                }
            },
            showAddFence: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                openOrClose: false,//是否展开组织机构
                newTreeData: this.treeData,//组织机构数据
                isFenceIn: false,//是否显示长时间进围栏
                isFenceOut: false,//是否显示长时间出围栏
                allowEdit: this.isAdd,//是否禁止编辑
               /*  ruleFormCopy: {
                    fenceName: '',//围栏名称
                    orgCode: '', //组织机构
                    inFenceTime: '',//进围栏时间
                    inFenceType: '',//进围栏时间类型
                    outFenceTime: '',//出围栏时间
                    outFenceType: '', //出围栏类型
                    bindImeiSet: [],//复选框选中的值
                    fenceAction: [],
                    triggeringCondition: '',//选择进围栏还是出围栏
                    inFence: { //进围栏
                        stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'IN'
                    },
                    outFence: {//出围栏
                        stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'OUT'
                    },
                    conditionQueryList: [],//长时间围栏
                },//数据  */
                ruleForm: JSON.parse(JSON.stringify(this.editFenceObj)),
                rules: {
                    fenceName: [
                        {required: true, message: '请输入围栏名称', trigger: 'blur'},
                        { max: 20, message: '最长输入20个字', trigger: 'change' }
                    ]
                },
                orgnationList: [ //组织机构
                    {name: 'guangming', value: '光明企业'},
                    {name: 'guangming1', value: '光明企业1'},
                    {name: 'guangming2', value: '光明企业2'},
                ],
                linkEquimentList: [ ],//关联的设备
                list: [
                    {title: '离线时间大于', timeKey: 'offlineTime', typeKey: 'offlineType'},
                    {title: '停留时间大于', timeKey: 'stayTime', typeKey: 'stayType'},
                    {title: '进入围栏时间大于', timeKey: 'inFenceTime', typeKey: 'inFenceType'},
                    {title: '离开围栏时间大于', timeKey: 'outFenceTime', typeKey: 'outFenceType'},
                ],
                inFenceTimeList: [1,2,3,4,5,6,7,8,9,10],//进围栏时间选择
                outFenceTimeList: [1,2,3,4,5,6,7,8,9,10],//出围栏时间选择
                typeList: [//时间类型
                    {name: 'SECONDS', value: '秒'},
                    {name: 'MINUTES', value: '分'},
                    {name: 'HOURS', value: '时'},
                    {name: 'DAYS', value: '天'},
                ],
                hours: [],//存储时
                minutea: [],//村塾分
                seconds: [],//存储秒
                num59: [], //存储1-59
                num23: [],//存储1-23
            };
        },
        created() {
            for(let i = 1; i < 24; i++) {
                this.num23.push(i);
            }
            for(let i = 1; i < 60; i++) {
                this.num59.push(i);
            }
            // this.listTreeOrg();
            this.getDeviceList();
        },
        mounted() {

        },
        methods: {

            /** 进出围栏显示 
             * @param {String} val 选择的值
            */
            changeCheckFence(val) {
                this.isFenceIn = (val.toString()).indexOf('IN') != -1 ? true : false;
                this.isFenceOut = (val.toString()).indexOf('OUT') != -1 ? true : false;
            },
            /** 
             * 切换进围栏时间类型
             */
            changeInFenceType() {
                if (this.ruleForm['inFence'].timeUnit == 'HOURS') {
                    this.inFenceTimeList = this.num23;
                } else {
                    this.inFenceTimeList = this.num59;
                }
            },
            /** 
             * 切换出围栏时间类型
             */
            changeOutFenceType(val) {
                if (this.ruleForm['outFence'].timeUnit == 'HOURS') {
                    this.outFenceTimeList = this.num23;
                } else {
                    this.outFenceTimeList = this.num59;
                }
            },
            /** 
             * 点击节点
             * @param {Object} obj 选中的机构
             * */
            nodeClick(obj, flag){
                this.ruleForm.orgCode = obj.orgId;
                this.ruleForm.orgName = obj.orgName;
                this.ruleForm.fullUserId = obj.orgCode;
                this.getDeviceList();
                if(flag == 'db') {
                    this.openOrClose = false
                }
            },
            /** 获取设备
             */
            getDeviceList() {
                let obj = {
                    orgId: this.ruleForm.orgCode
                };
                getDeviceList(obj).then(res => {
                    if (res.code == 200) {
                        this.linkEquimentList = res.data;
                        if (res.data.length > 0) {
                            this.ruleForm.orgName = res.data[0].orgName
                        }
                        
                    }
                });
            },
            /** 取消
             * 
             */
            addFenceCancel(val) {
                this.$refs[val].resetFields();
                this.$emit('addFenceCancel');
                this.isFenceIn = false;
                this.isFenceOut = false;
                this.openOrClose = false
            },
            /** 确定
             * @param {String} val 
             */
            confirmAddFence(val) {
                this.$refs[val].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.ruleForm['conditionQueryList'] = [];
                    if (this.isFenceIn && this.ruleForm['inFence'].stayTime) {
                        this.ruleForm['conditionQueryList'].push(this.ruleForm['inFence']);
                    } 
                    if (this.isFenceOut && this.ruleForm['outFence'].stayTime) {
                        this.ruleForm['conditionQueryList'].push(this.ruleForm['outFence'])
                    }
                    if (!this.ruleForm['inFence'].stayTime && !this.ruleForm['outFence'].stayTime) {
                        delete this.ruleForm['conditionQueryList'];
                    }
                    if (this.ruleForm['fenceAction'].length > 1) {
                        this.ruleForm.triggeringCondition = 'IN_AND_OUT';
                    } else if (this.ruleForm['fenceAction'].length < 1) {
                        this.ruleForm.triggeringCondition = 'NONE';
                        // delete this.ruleForm.triggeringCondition;
                    } else {
                        this.ruleForm.triggeringCondition = this.ruleForm['fenceAction'].toString();
                    }
                    let tempObj = {
                        bindImeiSet: this.ruleForm.bindImeiSet,
                        id: this.ruleForm.id,
                        fenceName: this.ruleForm.fenceName,
                        fullUserId: this.ruleForm.fullUserId,
                        triggeringCondition: this.ruleForm.triggeringCondition,
                        fenceSign: 'normalFence',
                        orgName: this.ruleForm.orgName,
                        orgId: this.ruleForm.orgCode
                    };
                    if (this.ruleForm['conditionQueryList']) {
                        tempObj['conditionQueryList'] = this.ruleForm['conditionQueryList']
                    }
                    this.$emit('confirmAddFence', tempObj);
                    this.isFenceIn = false;
                    this.isFenceOut = false;
                    this.openOrClose = false;
                });
            }
        },
        watch: {
            editFenceObj: {
                handler(val) {
                    this.getDeviceList();
                    this.ruleForm['bindImeiSet'] = val.bindImeiSet;
                    this.ruleForm['fenceAction'] = [];
                    if (val.triggeringCondition) {
                        if (val.triggeringCondition == 'IN_AND_OUT') {
                            this.ruleForm['fenceAction'] = ['IN', 'OUT'];
                        } else {
                            this.ruleForm['fenceAction'].push(val.triggeringCondition);
                        }
                    } 
                    this.ruleForm.inFence = {stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'IN'};
                    this.ruleForm.outFence = {stayTime: '', timeUnit: 'SECONDS', triggeringCondition: 'OUT'};
                    if (val.conditionVo && val.conditionVo.length > 0) {
                         
                        val.conditionVo.forEach(item => {
                            if (item.triggeringCondition == 'IN') {
                                this.isFenceIn = true;
                                this.ruleForm.inFence = item;
                            }
                            if (item.triggeringCondition == 'OUT') {
                                this.isFenceOut = true;
                                this.ruleForm.outFence = item;
                            }
                        })
                    } 
                    this.ruleForm['fenceName'] = val.fenceName;
                    this.ruleForm['orgCode'] = val.orgCode;
                    this.ruleForm['id'] = val.id;
                    this.ruleForm.orgName = val.orgName;
                },
                deep: true,
                immediate: true,
            },
            isAdd: {
                handler(val) {
                    this.allowEdit = val;
                },
                deep: true
            },
            treeData:{
                handler(newVal, oldVal) {
                    this.newTreeData = newVal;
                },
                deep: true,
            },
            showAddFence: {
                handler(val) {
                    if (!val) {
                        this.isFenceIn = false;
                        this.isFenceOut = false;
                        this.openOrClose = false;
                        this.$refs.ruleForm.resetFields();
                    }
                },
                deep: true
            }
        }
    }
</script>
<style scoped lang="scss">
    .add-fence-container {
        &.clearfix:before {
            display: block;
        }
        .m-l32 {
            margin-left: 32px;
        }
        .equiment-container {
            .equiment-label {
                margin-bottom: 8px;
                font-weight: 400;
                font-size: 14px;
                color: #262626;
            }
            .equiment-content-container { 
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                .equiment-content {
                    padding: 16px 32px;
                    max-height: 272px;
                    overflow: auto;
                    ul {
                        li {
                            display: flex;
                            align-items: center;
                            height: 32px;
                            line-height: 32px;  
                            font-weight: 400; 
                            color: #595959;
                        }
                    }
                    
                }
                
            }
        }
        .alarm-trigger-container {
            .alarm-header-title {
                float: left;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                color: #262626;
            }
            .check-fence-action {
                float: right;
                margin-left: 32px;
                line-height: 22px;
            }
            .alarm-content-container {
                margin-top: 10px;
                &.clearfix:before {
                    display: block;
                }
                .alarm-item {
                    height: 32px;
                    margin-bottom: 24px;
                    .alarm-title {
                        float: left;
                        width: 113px;
                        margin-right: 12px;
                        text-align: right;
                        line-height: 32px;
                        color: #262626;
                    }
                    .alarm-content {
                        float: left;
                        .select-first {
                            width: 94px;
                            height: 32px;
                        }
                        .select-second {
                            margin-left: 12px;
                        }
                    }
                }
                .el-row::after {
                    display: inline;
                }
                .el-row::before {
                    display: inline;
                }
            }   
        }
        .m-b12 {
            margin-bottom: 12px;
        }
        .btn-container {
            text-align: right;
            .btn-cancel {
                display: inline-block;
                margin-right: 12px;
            }
            .btn-close {
                display: inline-block;
            }
        }
        .m-b0 {
            margin-bottom: 0;
        }
    }
</style>
<style>
    /* .add-fence-container .el-form-item__content {
        width: 292px;
    } */
    .add-fence-container .left {
        float: left;
    }
    .add-fence-container .el-select {
        width: 100%;
    }
    .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
        content: '';
        width: 0;
        margin-right: 0;
        color: transparent;
    }
    .select-first .el-input__icon {
        line-height: 32px;
    }
    .m-l0 .el-form-item__content {
        margin-left: 0 !important;
    }
    .organization-tree .pop-close-style {

    }
    ::-webkit-scrollbar {
        width: 5px;
    }
</style>